import { SplitWrapper } from '@rategravity/marketing-components';
import React from 'react';
import { PageView } from './src/components/page-view';
import { SEO } from './src/components/seo';
import { metadata } from './src/data/metadata/pages';

export const PageWrapper = ({
  element,
  props: {
    location: { pathname }
  }
}) => (
  <React.Fragment>
    <SplitWrapper />
    {process.env.STORYBOOK_ENV === 'true' ? null : <SEO {...metadata[pathname]} />}
    <PageView>{element}</PageView>
  </React.Fragment>
);
