import * as Sentry from '@sentry/gatsby';
import { BrowserTracing } from '@sentry/tracing';

const {
  GATSBY_PROD,
  URL // automatically set by Netlify. Undefined otherwise.
} = process.env;

const isProd = URL && GATSBY_PROD === 'true';

Sentry.init({
  dsn: 'https://d9f30c4641c04f93abd531b9f8514fff@o80434.ingest.sentry.io/6155007',
  environment: isProd ? 'prod' : 'staging',
  integrations: [new BrowserTracing()],
  release: isProd ? process.env.COMMIT_REF : undefined,
  tracesSampleRate: isProd ? 0.00001 : 1.0
});
